export default defineNuxtRouteMiddleware((to, from) => {
  const logPaths = ['/checkout/undefined']
  const logger = useServerLogging('navigation')

  if (logPaths.some(path => to.path.includes(path))) {
    logger.logError({
      message: `Navigated to ${to.path} from ${from.path}`,
      code: `navigation_error_${to.path}`,
      data: `from: ${from.path}, to: ${to.path}, to: ${JSON.stringify(to)}, from: ${JSON.stringify(from)}`,
    })
  }
})
