import type { Service } from '~/server/api/diagnostics/log'

interface Payload {
  message: string
  code?: string
  data?: string
}

export function useServerLogging(serviceName: Service) {
  async function logError(payload: Payload) {
    try {
      await $fetch('/api/diagnostics/log', {
        method: 'POST',
        body: {
          ...payload,
          code: `${serviceName}_${payload.code}`,
          level: 'error',
          service: serviceName,
        },
      })
    }
    catch (error) {
    }
  }

  async function logInfo(payload: Payload) {
    try {
      await $fetch('/api/diagnostics/log', {
        method: 'POST',
        body: {
          ...payload,
          code: `${serviceName}_${payload.code}`,
          level: 'info',
          service: serviceName,
        },
      })
    }
    catch (error) {
    }
  }

  async function logWarning(payload: Payload) {
    try {
      await $fetch('/api/diagnostics/log', {
        method: 'POST',
        body: {
          ...payload,
          code: `${serviceName}_${payload.code}`,
          level: 'warn',
          service: serviceName,
        },
      })
    }
    catch (error) {
    }
  }

  return {
    logError,
    logInfo,
    logWarning,
  }
}
