import { isBoolean, isNumber, isPrimitive } from 'types/generic/data-type.guards'
import { type PayloadOptions, getTranslationFunction } from './validationRules'
import { postCodes } from '~/data/patterns/postcodes'
import type { ValidationRules } from '~/types/validation'
import { validators } from '~/lib/validation/validators'

export function validateField(_value: any, rulesValue: ValidationRules, options?: PayloadOptions) {
  const validation = {
    errors: [] as string[],
    valid: true,
  }

  const $t = getTranslationFunction(options)

  if (!isPrimitive(_value))
    throw new TypeError(`Invalid value type for validation: ${typeof _value}`)

  const value = _value as string | number | boolean

  const numericValue = isNumber(value)
  const booleanValue = isBoolean(value)

  try {
    if (rulesValue.required?.value && !validators.isRequired(value))
      validation.errors.push(rulesValue.required?.errorMessage || $t('errorRequired'))

    if (booleanValue)
      return // no more boolean validation, skip to finally

    if (rulesValue.min && !validators.isMin(rulesValue.min.value, value as string | number)) {
      const errorKey = isNumber(value) ? 'errorTooSmall' : 'errorTooShortChar'
      validation.errors.push(
        rulesValue.min?.errorMessage
        || $t(errorKey, { minLimit: rulesValue.min.value.toString() }),
      )
    }

    if (rulesValue.max && !validators.isMax(rulesValue.max.value, value as string | number)) {
      const errorKey = isNumber(value) ? 'errorTooLarge' : 'errorTooManyChar'
      validation.errors.push(
        rulesValue.max.errorMessage || $t(errorKey, { maxLimit: rulesValue.max.value.toString() }),
      )
    }

    if (numericValue)
      return // Skip to finally

    if (rulesValue.pattern?.value === 'email' && !validators.isEmail(value as string))
      validation.errors.push(rulesValue.pattern?.errorMessage || $t('errorEmailPatternNotValid'))

    if (value && rulesValue.pattern?.value === 'latin' && !validators.isLatin(value as string))
      validation.errors.push($t('errorNonLatin'))

    if (value && rulesValue.pattern?.value === 'latinAndContainsNumber' && !validators.isLatinAndContainsNumber(value as string))
      validation.errors.push($t('errorLatinAndContainsNumber'))

    if (rulesValue.pattern?.value === 'phone' && !validators.isPhone(value as string))
      validation.errors.push(rulesValue.pattern?.errorMessage || $t('errorPhonePatternNotValid'))

    if (rulesValue.pattern?.value === 'postcode') {
      const country = rulesValue.pattern.data.country

      if (value && !validators.isPostCode(country, value as string)) {
        validation.errors.push(
          rulesValue.pattern?.errorMessage || postCodes[country].errorMessage,
        )
      }
    }
  }
  catch (e) {
    console.error(`ValidationError`, e)
  }

  const { errors } = validation

  validation.valid = errors.length === 0

  return validation
}
