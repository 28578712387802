/// <reference types="applepayjs" />
import type * as Contact from 'types/models/contact.model'
import type { CartItem, ShippingMethod } from '~/types/cart'
import { isValidEnvironmentCountryCode } from '~/types/guards/storefront'

export function getShippingMethodLineItem(shippingMethod: ApplePayJS.ApplePayShippingMethod): ApplePayJS.ApplePayLineItem {
  return {
    label: `Delivery: ${shippingMethod.label}`,
    amount: shippingMethod.amount,
    type: 'final' as const,
  }
}

export function normalizeApplePayTotal(totalPrice: number): ApplePayJS.ApplePayLineItem {
  return {
    label: 'HAPPY SOCKS',
    amount: totalPrice.toString(),
  }
}
export function convertFloatAmountToAdyenAmount(totalPrice: string): number {
  if (totalPrice.includes('.')) {
    return Number(totalPrice.replace('.', ''))
  }
  return Number(`${totalPrice}00`)
}

export function createApplePayAmountHelper() {
  let applePayTotal: any = null

  return {
    getFinalAdyenAmount() {
      const amount = convertFloatAmountToAdyenAmount(applePayTotal.amount)

      return amount
    },
    getApplePayTotal() {
      return applePayTotal
    },
    setApplePayTotal(newTotal: any) {
      applePayTotal = newTotal
    },
  }
}

export function normalizeShippingMethod(shippingMethod: ShippingMethod): ApplePayJS.ApplePayShippingMethod {
  return {
    label: shippingMethod.title,
    detail: shippingMethod.deliveryDetails,
    amount: String(shippingMethod.price),
    identifier: shippingMethod.id,
  }
}

export function normalizeCartItem(item: CartItem): ApplePayJS.ApplePayLineItem {
  const amount = item.price.final * item.quantity

  return {
    label: item.name,
    amount: amount.toString(),
    type: 'final' as const,
  }
}

export function normalizeAddress(address: ApplePayJS.ApplePayPaymentContact): { address: Contact.Address, information: Contact.Information } {
  const storefrontStore = useStorefrontStore()
  const { countryCode } = storefrontStore.currentMarket

  const addressCountryCode = address.countryCode ? isValidEnvironmentCountryCode(address.countryCode) ? address.countryCode : countryCode : countryCode

  return {
    address: {
      country: addressCountryCode,
      city: address.locality || '',
      street0: address.addressLines?.[0] || '',
      street1: address.addressLines?.[1] || '',
      lastName: address.familyName || '',
      firstName: address.givenName || '',
      postCode: address.postalCode || '',
      region: address.administrativeArea || '',
    },
    information: {
      telephone: address.phoneNumber || '',
      email: address.emailAddress || '',
      agreements: true,
      newsletterSubscription: false,
    },
  }
}
