import type { Address } from '~/types/models/contact.model'

export function getDefaultAddress(): Address {
  return {
    city: '',
    street0: '',
    region: '',
    postCode: '',
    lastName: '',
    firstName: '',
  }
}
