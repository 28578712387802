import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45early_45bird_45extension_45global from "/vercel/path0/middleware/1.earlyBirdExtension.global.ts";
import _3_45gtm_45global from "/vercel/path0/middleware/3.gtm.global.ts";
import _4_45navigation_45global from "/vercel/path0/middleware/4.navigation.global.ts";
import log_45global from "/vercel/path0/middleware/log.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _1_45early_45bird_45extension_45global,
  _3_45gtm_45global,
  _4_45navigation_45global,
  log_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}