import type { Environment } from 'types/environment'
import { postCodes } from 'data/patterns/postcodes'
import type { Address, Information } from '~/types/models/contact.model'
import type { ValidationRules } from '~/types/validation'

import { REGION_REQUIRED_COUNTRIES, STORES_WITH_OPTIONAL_POSTCODE } from '~/configuration/global.configuration'
import type { TranslationFunction } from '~/utils/storyblok/translate'

type ValidationRulesAddress = Record<keyof Address, ValidationRules>
type ValidationRulesContact = Record<keyof Information, ValidationRules>
interface ValidationRulesByCheckout {
  address: ValidationRulesAddress
  contact: ValidationRulesContact
}

export interface PayloadOptions {
  $t?: TranslationFunction
}

export function getTranslationFunction(options?: PayloadOptions): TranslationFunction {
  if (options?.$t) {
    return options.$t
  }

  try {
    const storyblokStore = useStoryblokStore()
    return storyblokStore.getTranslation
  }
  catch (e) {
    return (key: string) => key
  }
}

export function getStreet0ValidationRule(countryCode: Environment.CountryCode, options?: PayloadOptions): ValidationRules {
  const isCountryWithoutHouseNumber = countryCode === 'GB'
  const streetPatternValue = isCountryWithoutHouseNumber ? 'latin' : 'latinAndContainsNumber'
  const $t = getTranslationFunction(options)

  return {
    required: {
      value: true,
      errorMessage: $t('errorAddress1Empty'),
    },
    max: {
      value: 30,
    },
    pattern: {
      value: streetPatternValue,
    },
  }
}

export function getPostcodeValidationRule(countryCode: Environment.CountryCode, options?: PayloadOptions): ValidationRules {
  const $t = getTranslationFunction(options)

  return {
    required: {
      value: !STORES_WITH_OPTIONAL_POSTCODE.includes(countryCode),
      errorMessage: postCodes[countryCode]?.emptyMessage || $t('errorPostcodeEmpty'),
    },
    max: {
      value: 20,
    },
    pattern: {
      value: 'postcode',
      data: {
        country: countryCode,
      },
    },
  }
}

export function getValidationRules(countryCode: Environment.CountryCode, options: PayloadOptions): ValidationRulesByCheckout {
  const $t = getTranslationFunction(options)
  const isRegionRequired = REGION_REQUIRED_COUNTRIES.includes(countryCode)

  const rules: ValidationRulesByCheckout = {
    address: {
      city: {
        required: { value: true, errorMessage: $t('errorCityTownEmpty') },
        max: { value: 30 },
        pattern: {
          value: 'latin',
        },
      },
      street0: getStreet0ValidationRule(countryCode, options),
      street1: {
        required: { value: false },
        max: { value: 30 },
        pattern: {
          value: 'latin',
        },
      },
      postCode: getPostcodeValidationRule(countryCode, options),
      lastName: {
        required: {
          value: true,
          errorMessage: $t('errorNameEmpty'),
        },
        max: {
          value: 250,
        },
        pattern: {
          value: 'latin',
        },
      },
      firstName: {
        required: {
          value: true,
          errorMessage: $t('errorNameEmpty'),
        },
        max: {
          value: 250,
        },
        pattern: {
          value: 'latin',
        },
      },
      country: {
        required: {
          value: true,
          errorMessage: $t('errorRequired'),
        },
      },
      region: {
        required: {
          value: isRegionRequired,
          errorMessage: $t('errorRequired'),
        },
      },
    },
    contact: {
      email: {
        required: {
          value: true,
          errorMessage: $t('errorEmailEmpty'),
        },
        max: {
          value: 250,
        },
        pattern: {
          value: 'email',
        },
      },
      telephone: {
        required: {
          value: true,
          errorMessage: $t('errorPhoneEmpty'),
        },
        max: {
          value: 30,
        },
        pattern: {
          value: 'phone',
        },
      },
      agreements: {
        required: {
          value: true,
          errorMessage: $t('errorAgreementsEmpty'),
        },
      },
      newsletterSubscription: {
        required: {
          value: false,
        },
      },
    },
  }

  return rules
}
