import type { CartItem } from '~/types/cart'

export function getPriceProducts(items: CartItem[]): number {
  return items.reduce((acc, item) => {
    const price = item.productDiscount ? item.price.original - item.productDiscount : item.price.final

    const totalPrice = price * item.quantity

    return acc + totalPrice
  }, 0)
}
