import { z } from 'zod'

export const TaxForOrderSchema = z.object({
  total: z.number(),
  lineItems: z.array(
    z.object({
      id: z.string(),
      amount: z.number(),
    }),
  ),
})

export type TaxForOrder = z.infer<typeof TaxForOrderSchema>

export const DEFAULT_TAXES_OBJECT: TaxForOrder = {
  total: 0,
  lineItems: [],
}
