import type { Market } from '~/types/models/market'

export function getDefaultMarket(): Market {
  return {
    countryCode: 'GB',
    storefrontCode: 'uk',
    contentLocale: 'en-GB',
    isFocusMarket: true,
    tax: 20,
    name: {
      local: 'United Kingdom',
      english: 'United Kingdom',
    },
    continent: 'EU',
    regions: [],
    locale: {
      html: 'en-GB',
      iso: 'en-GB',
    },
    warehouse: {
      region: 'NL',
      codes: ['nl'],
    },
    currency: {
      code: 'GBP',
      symbol: '£',
    },
    language: 'en',
    shipping: {
      freeShippingThreshold: 50,
    },
  }
}
