import type { Environment } from 'types/environment'
import { getValidationRules } from './validationRules'

import { validateField } from './validateField'
import type { Address } from '~/types/models/contact.model'

import type { TranslationFunction } from '~/utils/storyblok/translate'

interface ReturnType {
  valid: boolean
  errors: string[]
  errorsPerKey: Record<keyof Address, string[]>
}

interface Options {
  countryCode: Environment.CountryCode
  $t?: TranslationFunction
}

export function validateAddress(address: Address, options: Options): ReturnType {
  const errors: Set<string> = new Set()
  const errorsPerKey: Record<keyof Address, string[]> = {
    city: [],
    street0: [],
    street1: [],
    region: [],
    country: [],
    postCode: [],
    lastName: [],
    firstName: [],
  }
  const validationRules = getValidationRules(options.countryCode, options)

  for (const _key in address) {
    const key = _key as keyof Address
    const value = address[key]
    const rules = validationRules.address[key]

    try {
      const validation = validateField(value, rules, options)

      if (validation && !validation.valid) {
        errorsPerKey[key] = validation.errors
        validation.errors.forEach(error => errors.add(error))
      }
    }
    catch (e) {
      console.error(`ValidationError`, e)
    }
  }

  const valid = errors.size === 0

  return {
    valid,
    errors: Array.from(errors),
    errorsPerKey,
  }
}
