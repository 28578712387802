type Translations = {
  name: string
  value: string
}[]

export type TranslationFunction = (key: string, params?: Record<string, string>) => string

export function getTranslation(translations: Translations, name: string, format: Record<string, string> = {}): string {
  const translation = translations.find(translation => translation.name === name)
  if (!translation)
    return name

  // Replace all substrings surrounded by {} by key
  // Hello: {name} -> Hello: Brian
  const regex = /\{([^{]+)\}/g
  return translation.value.replace(regex, (_, key) => {
    const term = format[key]
    return term === undefined ? `{${key}}` : term
  })
}
