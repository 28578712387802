import { pathFromRoute } from 'lib/routing'

import type { Datasource, Story } from 'types/storyblok'
import type { Route } from 'types/route'
import { logError } from 'lib/logging'
import { getConstantsDefault, getTranslationsDefault } from 'utils/storyblok/shared-content'
import { callWithNuxt } from '#app'
import type { SharedContentNormalized } from '~/types/storyblok/shared-content'
import type { SizeGuide } from '~/types/storyblok/shared-content/size-guides'
import { getTranslation as _getTranslation } from '~/utils/storyblok/translate'

interface StoryblokState extends SharedContentNormalized {
  translations: Datasource[]
  constants: Datasource[]
  protected: { [key: string]: string }
  stories: {
    [slug: string]: Story<{ [key: string]: any }>
  }
}

export const useStoryblokStore = defineStore('StoryblokStore', () => {
  const state = ref<StoryblokState>({
    topBanner: undefined,
    checkoutBanner: undefined,
    pdpFeaturesIcons: [],
    pdpCertifications: [],
    pdpShippingAndReturns: undefined,
    pdpSustainability: undefined,
    newsletter: undefined,
    checkoutNewsletterCheckbox: undefined,
    translations: [],
    constants: [],
    submenus: {},
    submenusAlternate: {},
    protected: {},
    sizeGuides: [],
    pdpEnhancedBlocks: [],
    stories: {},
    categories: undefined,
    headerNavbar: undefined,
    headerNavbarAlternate: undefined,
    speechBubbleStates: undefined,
    footer: undefined,
    facets: [],
    sidebarFilters: undefined,
    segmentedUpsellStories: [],
  })

  const { $storyblok } = useNuxtApp()

  const getStory = (slug: string) => {
    const story = state.value.stories[slug]

    if (!story)
      throw new Error(`Story not found: ${slug}`)

    return story
  }

  const getSizeGuide = (key: string) =>
    state.value.sizeGuides.find(guide => guide.identifier === key)

  const hasStorySubmenu = (path: string) =>
    state.value.stories[path]?.content.header_layout === 'header--submenu'

  function getTranslation(name: string, format: Record<string, string> = {}) {
    return _getTranslation(state.value.translations, name, format)
  }

  function getConstant(name: string) {
    return state.value.constants.find(v => v.name === name)
  }

  const getStorySiblings = (route: Route) => {
    // TODO: Might want to computed different values inside here.
    const path = pathFromRoute(route)
    const story = state.value.stories[path]
    // NOTE: For siblings we check /uk/parent-slug for a path that is /uk/parent-slug/child-slug
    const parentSlug = story?.full_slug?.substring(0, story?.full_slug.lastIndexOf('/'))

    return Object.keys(state.value.stories)
      .filter(story => state.value.stories[story]?.full_slug.startsWith(parentSlug || ''))
      .map(item => state.value.stories[item])
  }

  const getPdpFeaturesIcon = (keys: string[]) => {
    return keys.map((key) => {
      const icon = state.value.pdpFeaturesIcons?.find(icon => icon.key === key)
      if (icon)
        return icon
      return {
        id: `${key}-default-icon`,
        key,
        image: {
          id: `${key}-default-icon-id`,
          url: '/icons/logo-hs.svg',
          alt: 'smiley-icon',
        },
      }
    })
  }

  const setStory = (story: Story<any>, path: string) => {
    state.value.stories[path.replace(/\/$/, '')] = story
  }

  const setSizeGuides = (sizeGuides: SizeGuide[]) => {
    sizeGuides.forEach((guide) => {
      state.value.sizeGuides[guide.identifier] = guide
    })
  }

  const fetchTranslations = async () => {
    const nuxtApp = useNuxtApp()
    let data

    try {
      data = await $storyblok.fetchTranslations()
    }
    catch (e) {
      callWithNuxt(nuxtApp, logError, ['Failed to fetch translations, fetching defaults...', e])
      data = await getTranslationsDefault(useStorefrontStore().current.contentLocale)
    }

    state.value.translations = data
    return Promise.resolve(data)
  }

  async function fetchConstants() {
    const nuxtApp = useNuxtApp()

    let data
    try {
      data = await $storyblok.fetchConstants()
    }
    catch (e) {
      callWithNuxt(nuxtApp, logError, ['Failed to fetch constants, fetching defaults...', e])
      data = await getConstantsDefault()
    }

    state.value.constants = data
    return Promise.resolve(data)
  }

  const fetchStory = async (path: string) => {
    const nuxtApp = useNuxtApp()
    try {
      const story = await $storyblok.fetchStory(path)
      setStory(story, path)
      return Promise.resolve(story)
    }
    catch (e) {
      callWithNuxt(nuxtApp, logError, ['Failed to fetch story', e])
      return Promise.reject(e)
    }
  }

  const fetchSiblings = async (path: string) => {
    const nuxtApp = useNuxtApp()
    try {
      const stories = await $storyblok.fetchSiblings(path)
      stories.map((story: any) => setStory(story, story.full_slug))
      return Promise.resolve()
    }
    catch (e) {
      callWithNuxt(nuxtApp, logError, ['Failed to fetch story siblings', e])
      return Promise.reject(e)
    }
  }
  const fetchSharedContent = async (constants: Datasource[]) => {
    try {
      const data = await $storyblok.fetchSharedContent(constants)

      state.value = {
        ...state.value,
        ...data,
      }

      setSizeGuides(data.sizeGuides)
      return Promise.resolve()
    }
    catch (e) {
      const nuxtApp = useNuxtApp()
      callWithNuxt(nuxtApp, logError, ['Failed to fetch shared content and defaults...', e])

      return Promise.reject(e)
    }
  }

  return {
    state,
    getStory,
    getSizeGuide,
    hasStorySubmenu,
    getTranslation,
    getConstant,
    getStorySiblings,
    getPdpFeaturesIcon,
    setStory,
    setSizeGuides,
    fetchTranslations,
    fetchConstants,
    fetchStory,
    fetchSiblings,
    fetchSharedContent,
  }
})
