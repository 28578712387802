import type { CheckoutInformation } from 'types/checkout'
import type * as Contact from 'types/models/contact.model'
import type * as Brink from 'types/vendors/brink'

// TODO: Extract the data from this file and move the guard to another file instead

export const DEFAULT_ADDRESS: Contact.Address = {
  city: '',
  firstName: '',
  lastName: '',
  postCode: '',
  country: undefined,
  street0: '',
  street1: '',
  region: '',
}

export const DUMMY_ADDRESS: Contact.Address = {
  city: 'Dummy City',
  firstName: 'Dummy First Name',
  lastName: 'Dummy Last Name',
  postCode: '12345',
  country: 'GB',
  street0: 'Dummy Street',
  street1: 'Dummy Street 2',
  region: 'Dummy Region',
}

export const DEFAULT_CONTACT = {
  email: '',
  telephone: '',
  agreements: false,
  newsletterSubscription: false,
}

export const DUMMY_CONTACT: Contact.Information = {
  email: 'dummy@dummy.com',
  telephone: '1234567890',
  agreements: true,
  newsletterSubscription: false,
}

export const DEFAULT_CHECKOUT_INFORMATION: CheckoutInformation = {
  address: {
    billing: { ...DEFAULT_ADDRESS },
    shipping: { ...DEFAULT_ADDRESS },
    useShippingForBilling: true,
  },
  contact: { ...DEFAULT_CONTACT },
}

export function isCartSessionResponse(response: any): response is Brink.CartSessionResponse {
  return response && Reflect.has(response, 'jwtToken')
}
