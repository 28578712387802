import type { ContentRepository } from '~/contracts/ContentRepositoryContract'
import { getMainStory } from '~/lib/content/getMainStory'
import { getStoryApiUrlParams, normalizeRichtextDeprecated } from '~/lib/storyblok'

import { fetchSharedContent } from '~/utils/storyblok/sharedContent/fetchSharedContent'
import { normalizeSharedContent } from '~/utils/storyblok/sharedContent/normalizeSharedContent'
import { getSharedContentDefault } from '~/utils/storyblok/shared-content'
import { normalizeDatasource } from '~/utils/storyblok/normalizeDatasources'

import type {
  Datasource,
  MultiStoryResponse,
  Story,
  StoryblokDatasourceRequestParameters,
} from '~/types/storyblok'
import type Storyblok from '~/types/vendors/storyblok'
import { logError } from '~/lib/logging'

export const StoryblokContentRepository: ContentRepository = ({
  baseURL,
  storefront,
  cacheControl,
  headers,
  queryParameters,
}) => {
  const api = $fetch.create({
    baseURL,
    headers: {
      'store-code': storefront.contentKey,
      'cache-control': cacheControl,
      ...headers,
    },
    params: {
      ...queryParameters,
    },
    async onRequest({ options, request }) {
      // eslint-disable-next-line ts/no-use-before-define
      repository.recentRequests.push({
        method: options.method?.toUpperCase(),
        url: request.toString(),
      })
    },
  })

  const repository: ReturnType<ContentRepository> = {
    recentRequests: [],
    _name: 'StoryblokContentRepository',
    normalizers: {
      normalizeRichtext(rawRichtext) {
        return normalizeRichtextDeprecated(rawRichtext)
      },
    },
    async fetchStory(url, cache = true) {
      try {
        const params = getStoryApiUrlParams(url, {
          cache,
          contentKey: storefront.contentKey,
        })

        const { stories } = await api<{ stories: Story[] }>(`/stories`, {
          headers: {
            'cache-control': cache ? 'undefined' : 'no-cache',
          },
          params,
        })

        const story = getMainStory(stories, storefront.contentKey, storefront.storefrontCode)

        if (!story)
          return Promise.reject(new EvalError('Story is undefined'))

        return Promise.resolve(story)
      }
      catch (e) {
        return Promise.reject(e)
      }
    },
    async fetchSiblings(slug) {
      try {
        const { stories } = await api<MultiStoryResponse>(`/stories/`, {
          params: { starts_with: `${slug}` },
        })
        return Promise.resolve(stories)
      }
      catch (e) {
        return Promise.reject(e)
      }
    },

    async fetchTranslations() {
      const language = storefront.contentLocale

      const params: StoryblokDatasourceRequestParameters = {
        per_page: 1000,
        datasource: 'translations',
      }

      if (language !== 'default')
        params.dimension = language

      try {
        const { datasource_entries } = await api<Storyblok.DatasourceResponse>(
          `/datasource_entries`,
          {
            params,
          },
        )

        if (!datasource_entries)
          return Promise.reject(new Error('Unable to fetch translations'))

        return Promise.resolve(normalizeDatasource(datasource_entries))
      }
      catch (e) {
        return Promise.reject(e)
      }
    },

    async fetchConstants() {
      try {
        const { datasource_entries } = await api<{
          datasource_entries: { dimension_value: null, id: number, name: string, value: string }[]
        }>(`/datasource_entries`, {
          method: 'GET',
          params: {
            per_page: 1000,
            datasource: 'constants',
          },
        })

        if (!datasource_entries)
          return Promise.reject(new Error('Unable to fetch constants'))

        return Promise.resolve(
          datasource_entries.map(({ name, value }) => ({
            name,
            value,
          })),
        )
      }
      catch (e) {
        return Promise.reject(e)
      }
    },

    // 2. handle response from storyblok
    async fetchSharedContent(constants: Datasource[]) {
      let data

      try {
        const {
          storyblokToken,
          public: { storyblokMode },
        } = useRuntimeConfig()
        data = await fetchSharedContent(api, storefront, constants, {
          token: storyblokToken,
          version: storyblokMode as 'draft' | 'published',
        })
      }
      catch (e) {
        logError('Failed to fetch Shared Content', e)
        data = await getSharedContentDefault(storefront.contentKey)
      }

      const { stories, categories } = data
      return normalizeSharedContent(storefront, stories, categories)
    },
  }

  return repository
}
