/// <reference types="applepayjs" />
import { type AddressData, AdyenCheckout, ApplePay, type ApplePayButtonType, type ApplePayConfiguration, type CoreConfiguration, type SubmitActions } from '@adyen/adyen-web'
import type * as Brink from 'types/vendors/brink'
import { useAdyenSupportFunctions } from './useAdyenSupportFunctions'
import { useApplePayOrder } from './useApplePayOrder'
import { getAdyenClientKey, getAdyenEnvironment, onAdyenSessionError } from '~/lib/adyen'
import { isValidEnvironmentCountryCode } from '~/types/guards/storefront'
import { APPLE_PAY_COUNTRY_ERROR, APPLE_PAY_FATAL_ERROR, APPLE_PAY_US_BILLING_COUNTRY_ERROR, WAREHOUSE_ERROR } from '~/configuration/global.configuration'
import { DUMMY_ADDRESS, DUMMY_CONTACT } from '~/lib/checkout'
import { validateAddress } from '~/models/checkout/validateAddress'

const logger = useServerLogging('apple_pay_express')

export async function useApplePay() {
  const context = useNuxtApp()
  const storefrontStore = useStorefrontStore()
  const checkoutStore = useCheckoutStore()
  const { storefrontCode } = storefrontStore.current
  const { locale, countryCode } = storefrontStore.currentMarket
  const adyenSupportFunctions = useAdyenSupportFunctions()
  const channel = 'Web'
  const languageCode = adyenSupportFunctions.getAdyenLanguage(locale.html)
  const {
    public: { adyenEnvironment, adyenClientKey, adyenClientKeyUs },
  } = useRuntimeConfig()
  const orderStore = useOrderStore()
  const clientKey = getAdyenClientKey(storefrontCode, {
    us: adyenClientKeyUs,
    default: adyenClientKey,
  })
  const getEnvironment = () => getAdyenEnvironment(storefrontStore.currentMarketCountryCode, adyenEnvironment)
  const applePayOrder = useApplePayOrder()
  applePayOrder.setMarket()

  const paymentMethodsResponse = await context.$payments.getPaymentMethods({ countryCode, languageCode, channel })

  function createConfig(amount: { currency: string, value: number }) {
    const config = {
      paymentMethodsResponse,
      clientKey,
      environment: getEnvironment(),
      amount,
      countryCode,
      locale: languageCode,
    }
    return config
  }

  async function createAdvancedFlowApplePay(expressErrorArray: Ref<string[]>, amount: { currency: string, value: number }, emit: any, orderSignature: globalThis.Ref<any, any>) {
    try {
      const config = createConfig(amount)

      const checkoutConfiguration: CoreConfiguration = {
        ...config,
        onSubmit: async (state: any, component: any, actions: SubmitActions) => {
          try {
            if (applePayOrder.isDifferentWarehouse()) {
              actions.reject()
              expressErrorArray.value.push(useNuxtApp().$t(WAREHOUSE_ERROR))
              throw new Error(WAREHOUSE_ERROR)
            }

            await handleOnSubmit(state, component, actions, expressErrorArray)
          }
          catch (error: any) {
            console.error('Error in onSubmit:', error)
            actions.reject()

            throw error
          }
        },
        onPaymentCompleted: async (result: Adyen.PaymentSessionResponse, component: any) => {
          try {
            if (orderSignature.value) {
              const order = applePayOrder.order.value
              if (!order)
                throw new Error('No order found')

              await orderStore.confirmOrder(orderSignature.value, order.id)
              return adyenSupportFunctions.handleServerResponse(result, component)
            }
            else {
              const order = applePayOrder.order.value
              if (!order)
                throw new Error('No order found')

              return adyenSupportFunctions.handleServerResponse(result, component)
            }
          }
          catch (error) {
            console.error('Error in onPaymentCompleted:', error)
            checkoutStore.loading = false
          }
        },
        onPaymentFailed: async (result: Adyen.PaymentSessionResponse) => {
          console.error('🚀 ~ onPaymentFailed ~ result:', result)
          if (result)
            console.error('Payment failed:', result.resultCode)
          checkoutStore.loading = false
        },
        onError: (error: any) => {
          console.error('🚀 ~ onError ~ error:', error)
          onAdyenSessionError(error)
          checkoutStore.loading = false
        },
      }

      const checkout = await AdyenCheckout(checkoutConfiguration)
      return checkout
    }
    catch (error) {
      console.error('Error in createAdvancedFlowApplePay:', error)
      checkoutStore.loading = false
    }
  }

  async function setUpApplePayExpress(checkout: any, expressErrorArray: Ref<string[]>) {
    applePayOrder.setItems()

    const applePayConfiguration: ApplePayConfiguration = {
      isExpress: true,
      expressPage: 'cart',
      buttonType: 'plain' as ApplePayButtonType,
      onClick: async (resolve: any, reject: any) => {
        try {
          applePayOrder.setMarket()
          applePayOrder.setItems()
          const shouldUpdateShippingMethods = applePayOrder.shouldUpdateShippingMethods()

          if (shouldUpdateShippingMethods)
            await applePayOrder.fetchShippingMethods()

          resolve()
        }
        catch (error) {
          console.error('Error in onClick:', error)
          reject(error)
        }
      },
      onAuthorized: (data: {
        authorizedEvent: ApplePayJS.ApplePayPaymentAuthorizedEvent
        billingAddress?: Partial<AddressData>
        deliveryAddress?: Partial<AddressData>
      }, actions: any) => {
        const billingAddress = data.authorizedEvent.payment.billingContact
        const deliveryAddress = data.authorizedEvent.payment.shippingContact

        const currentCountryCode = applePayOrder.order.value.market.countryCode
        const isDifferentCountry = currentCountryCode !== deliveryAddress?.countryCode

        const isUSAndBillingCountryDoesNotMatchShippingCountry = currentCountryCode === 'US' && billingAddress?.countryCode !== deliveryAddress?.countryCode

        if (isUSAndBillingCountryDoesNotMatchShippingCountry) {
          actions.reject()
          expressErrorArray.value.push(useNuxtApp().$t(APPLE_PAY_US_BILLING_COUNTRY_ERROR, { country: currentCountryCode }))
          throw new Error(APPLE_PAY_US_BILLING_COUNTRY_ERROR)
        }

        if (isDifferentCountry) {
          actions.reject()
          expressErrorArray.value.push(useNuxtApp().$t(APPLE_PAY_COUNTRY_ERROR, { country: currentCountryCode }))
          throw new Error(APPLE_PAY_COUNTRY_ERROR)
        }

        if (deliveryAddress) {
          try {
            applePayOrder.setAppleShippingAddress(deliveryAddress)
          }
          catch (error) {
            logger.logError({
              message: 'Apple Pay Express Error - Setting Shipping Address',
              code: 'setting_shipping_address',
              data: JSON.stringify({ deliveryAddress }),
            })
          }
        }
        else {
          logger.logError({
            message: 'Apple Pay Express Error - No Shipping Address',
            code: 'no_shipping_address',
            data: JSON.stringify({ deliveryAddress }),
          })
        }

        if (billingAddress) {
          try {
            applePayOrder.setAppleBillingAddress(billingAddress)
          }
          catch (error) {
            logger.logError({
              message: 'Apple Pay Express Error - Setting Billing Address',
              code: 'setting_billing_address',
              data: JSON.stringify({ billingAddress }),
            })
          }
        }
        else {
          logger.logError({
            message: 'Apple Pay Express Error - No Billing Address',
            code: 'no_billing_address',
            data: JSON.stringify({ billingAddress }),
          })
        }

        actions.resolve()
      },

      onPaymentFailed(data, component) {
        console.log('onPaymentFailed', data)
      },

      onError: (error: any) => {
        console.error('Error in Apple Pay:', error)

        if (error.name !== 'CANCEL') {
          logger.logInfo({
            message: `Apple Pay Express Error: ${error.name}`,
            code: 'error',
            data: JSON.stringify(error),
          })
        }
      },

      onComplete: () => {
        console.log('onComplete')
      },

      onPaymentMethodSelected: async (resolve: any, reject: any, event: ApplePayJS.ApplePayPaymentMethodSelectedEvent) => {
        try {
          const billingAddress = event.paymentMethod.billingContact

          if (billingAddress) {
            applePayOrder.setAppleBillingAddress(billingAddress)
          }

          const update = {
            newTotal: applePayOrder.getTotal().applePayTotal,
            newLineItems: applePayOrder.getAllAppleLineItems(),
          }

          resolve(update)
        }
        catch (error) {
          console.error('Error in onPaymentMethodSelected:', error)
          reject(error)
        }
      },

      onShippingContactSelected: async (resolve: any, reject, event: ApplePayJS.ApplePayShippingContactSelectedEvent) => {
        try {
          const countryCode = event.shippingContact.countryCode?.toUpperCase()
          const newTotal = applePayOrder.getTotal().applePayTotal
          const newLineItems = applePayOrder.getAllAppleLineItems()
          let update: ApplePayJS.ApplePayShippingContactUpdate = {
            newTotal,
            newLineItems,
          }
          expressErrorArray.value = ['']

          if (!countryCode || !isValidEnvironmentCountryCode(countryCode)) {
            console.error('Invalid country code:', countryCode)
            // update.errors = [
            //   {
            //     code: 'shippingContactInvalid',
            //     contactField: 'country',
            //     message: 'Invalid country code',
            //   },
            // ]
            resolve(update)
            return
          }

          const currentyCountryCode = applePayOrder.order.value.market.countryCode
          const isDifferentCountry = currentyCountryCode !== countryCode

          if (isDifferentCountry) {
            const message = useNuxtApp().$t(APPLE_PAY_COUNTRY_ERROR, { country: currentyCountryCode })
            expressErrorArray.value.push(message)
            // update.errors = [
            //   {
            //     code: 'shippingContactInvalid',
            //     contactField: 'country',
            //     message: 'Invalid country',
            //   },
            // ]
          }

          const deliveryAddress = event.shippingContact

          if (deliveryAddress) {
            applePayOrder.setAppleShippingAddress(deliveryAddress)
          }

          const newShippingMethods = applePayOrder.getAppleShippingMethods()

          update = {
            ...update,
            newTotal,
            newLineItems,
            newShippingMethods,
          }

          resolve(update)
        }
        catch (error) {
          console.error('Error in onShippingContactSelected:', error)
          reject(error)
          throw error
        }
      },

      onShippingMethodSelected: async (resolve: any, reject: any, event: ApplePayJS.ApplePayShippingMethodSelectedEvent) => {
        try {
          const { shippingMethod } = event
          await applePayOrder.setShippingMethod(shippingMethod)
          await applePayOrder.createOrder({ useDummyData: true })
          const newLineItems = applePayOrder.getAllAppleLineItems()
          const newTotal = applePayOrder.getTotal().applePayTotal

          const update: ApplePayJS.ApplePayShippingMethodUpdate = {
            newTotal,
            newLineItems,
          }

          resolve(update)
        }
        catch (error) {
          console.error('Error in onShippingMethodSelected:', error)
          const newLineItems = applePayOrder.getAllAppleLineItems()
          const newTotal = applePayOrder.getTotal().applePayTotal

          const update: ApplePayJS.ApplePayShippingMethodUpdate = {
            newTotal,
            newLineItems,
          }
          resolve(update)
        }
      },

      requiredBillingContactFields: ['postalAddress', 'phone', 'name'],
      requiredShippingContactFields: ['postalAddress', 'name', 'phone', 'email'],
    }

    try {
      const applePay = new ApplePay(checkout, applePayConfiguration)
      return applePay
    }
    catch (error) {
      console.error('Error setting up Apple Pay:', error)
      return null
    }
  }

  return {
    createAdvancedFlowApplePay,
    setUpApplePayExpress,
  }
}

async function handleOnSubmit(state: any, component: any, actions: any, expressErrorArray: Ref<string[]>) {
  try {
    const context = useNuxtApp()
    const orderApplePay = useApplePayOrder()
    const locale = orderApplePay.order.value.market.locale

    const shippingAddress = orderApplePay.getBrinkShippingAddress()
    const billingAddress = orderApplePay.getBrinkBillingAddress()
    const email = orderApplePay.getBrinkEmail()
    const orderId = orderApplePay.getOrderID()

    const option: any = {
      countryCode: orderApplePay.order.value.market.countryCode,
      $t: context.$t,
    }

    const validShipping = validateAddress(orderApplePay.getNormalizedShippingAddress(), option)

    if (!validShipping.valid) {
      actions.reject()
      expressErrorArray.value.push('Shipping address: ')
      validShipping.errors.forEach((error) => {
        expressErrorArray.value.push(error)
      })
      throw new Error(validShipping.errors.join(', '))
    }

    const validBilling = validateAddress(orderApplePay.getNormalizedBillingAddress(), option)

    if (!validBilling.valid) {
      actions.reject()
      expressErrorArray.value.push('Billing address: ')
      validBilling.errors.forEach((error) => {
        expressErrorArray.value.push(error)
      })
      throw new Error(validBilling.errors.join(', '))
    }

    if (billingAddress.givenName === DUMMY_ADDRESS.firstName
      || billingAddress.familyName === DUMMY_ADDRESS.lastName
      || billingAddress.streetAddress === DUMMY_ADDRESS.street0
      || email === DUMMY_CONTACT.email
      || shippingAddress.givenName === DUMMY_ADDRESS.firstName
      || shippingAddress.familyName === DUMMY_ADDRESS.lastName
      || shippingAddress.streetAddress === DUMMY_ADDRESS.street0) {
      actions.reject()

      expressErrorArray.value.push(useNuxtApp().$t(APPLE_PAY_FATAL_ERROR))

      logger.logError({
        message: 'Apple Pay Express Error - Dummy Data',
        code: 'dummy_data',
        data: JSON.stringify({ shippingAddress, billingAddress, email, orderId }),
      })
      throw new Error(APPLE_PAY_FATAL_ERROR)
    }

    const body: Brink.AdyenPaymentRequestBody = {
      redirect: {
        canceled: location.href,
        default: location.href,
        success: location.href.replace('cart', 'payment'),
        error: location.href,
      },
      orderId,
      origin: location.href,
      paymentMethod: state.data.paymentMethod,
      shopperLocale: locale.html,
      email,
      shippingAddress,
      billingAddress,
    }

    const paymentResponse = await context.$payments.requestPayments(body)

    if (!paymentResponse.paymentResult.resultCode) {
      actions.reject()
      return
    }

    const {
      resultCode,
      action,
      donationToken,
    } = paymentResponse.paymentResult

    const order = paymentResponse.order

    orderApplePay.saveOrder(order)

    actions.resolve({
      resultCode,
      action,
      order,
      donationToken,
    })
  }
  catch (error) {
    console.error('onSubmit', error)
    actions.reject()
  }
}
